import { List } from "antd";
import ContentArea from "components/content/Content";
import SkeletonLoader from "components/skeleton-loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useInfiniteQuery } from "react-query";
import contactService from "services/contact.service";
import ContactsHeader from "components/contact-header";
import ContactsList from "components/contact-list";
import ServerSideError from "components/server-side-error";
import NotFoundError from "components/not-found-module";
import hasMore from "utilities/common";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useEffect } from "react";
import useLocalStorage from "../hooks/useLocalStorage";
import WrapperExpiryCheck from "./wrapper/expiry-check";

function Contacts() {
  const { removeSession } = useLocalStorage();
  const navigate = useNavigate();
  let unauthorized = false;
  useEffect(() => {
    if (unauthorized) {
      navigate("/");
    }
  });
  const { data, fetchNextPage, isLoading, isError, error, refetch }: any =
    useInfiniteQuery(["contact-list"], contactService.list, {
      getNextPageParam: (_lastPage, pages) => {
        return pages.length + 1;
      },
      refetchOnWindowFocus: false,
      // cacheTime: 0,
      retry: (failureCount, err: AxiosError) => {
        // don't retry in case of 404 and 401
        return !!(
          failureCount &&
          (err.request.status === 404 || err.request.status === 401)
        );
      },
    });

  const renderContacts = () => {
    if (isError) {
      // if error is 401, remove token and navigate to home/login screen
      if (error?.request?.status === 401) {
        removeSession();
        unauthorized = true;
      }
      return <ServerSideError error={error} refetch={refetch} />;
    }

    const totalLength = data.pages
      .map((el: any) => el)
      .map((ele: any) => ele.data.list)
      .flat().length;

    if (totalLength > 0) {
      return (
        <InfiniteScroll
          dataLength={
            data.pages
              .map((el: any) => el)
              .map((ele: any) => ele.data.list)
              .flat().length
          }
          next={fetchNextPage}
          className="infinite-scroll-bar"
          hasMore={hasMore(data.pages)}
          loader={<SkeletonLoader />}
          endMessage=""
          scrollableTarget="scrollableDiv"
        >
          {data?.pages.map((group: any) => {
            const { list } = group.data;
            return list?.map((el: any) => {
              return (
                <List.Item
                  data-testid="contacts-test-id"
                  key={el.id}
                  style={{
                    borderBottom: "3px solid #EAEAEA",
                    position: "relative",
                    cursor: "pointer",
                  }}
                >
                  <ContactsList data={el} />
                </List.Item>
              );
            });
          })}
        </InfiniteScroll>
      );
    }

    return <NotFoundError message="There are no contacts in your feed" />;
  };

  return (
    <ContentArea>
      <ContactsHeader />
      {isLoading ? (
        <div data-testid="contact-loader">
          {" "}
          {[0, 1, 2, 3, 4].map((e: number) => (
            <SkeletonLoader key={e.toString()} />
          ))}
        </div>
      ) : (
        renderContacts()
      )}
    </ContentArea>
  );
}

export default WrapperExpiryCheck(Contacts);
