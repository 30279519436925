import type * as CSS from "csstype";

const container: CSS.Properties = {
  height: "100%",
  position: "absolute",
  right: 0,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default container;
