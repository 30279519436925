import { Skeleton } from "antd";

function SkeletonLoader() {
  return (
    <Skeleton
      style={{ paddingTop: 20, paddingBottom: 20 }}
      avatar
      paragraph={{ rows: 1 }}
      active
    />
  );
}

export default SkeletonLoader;
