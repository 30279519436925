import { FrownOutlined } from "@ant-design/icons";
import { Typography, Button } from "antd";

export default function ServerSideError({ error, refetch }: any) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      <FrownOutlined style={{ fontSize: 76, color: "#DCE0E6" }} />
      <Typography.Text style={{ paddingTop: 7 }} data-testid="server-error">
        {error.message}
      </Typography.Text>
      <Button
        style={{ marginTop: 10 }}
        onClick={() => refetch()}
        data-testid="refetch-button"
      >
        Refresh
      </Button>
    </div>
  );
}
