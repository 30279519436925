import useLogin from "hooks/useLogin";
import { Spin } from "antd";

import "../header/styles.css";

export default function HomeContentArea() {
  const { redirectToLoginProcess, isSpinning } = useLogin();

  return (
    <Spin spinning={isSpinning} data-testid={`${isSpinning}-loading`}>
      <div className="site-inner">
        <div className="content-sidebar-wrap">
          <main className="content" id="genesis-content">
            <div className="container" style={{ overflow: "hidden" }}>
              <article className="post-4254 page type-page status-publish entry">
                <div className="entry-content">
                  <section className="inner_banner_sec  alignfull">
                    <img
                      src="/assets/banner.png"
                      className="inner_banner_img"
                      alt="banner"
                    />
                    <div className="container">
                      <div className="banner_content">
                        <span
                          className="banner_label text_secondary_clr_dark_gray text_style_16 
        label_yallow"
                        >
                          REINTEGRATION
                        </span>
                        <h1 className="text_secondary_clr_dark_gray largest_heading">
                          WELCOME TO CITIZEN ACCESS
                        </h1>
                        <div>
                          <p className="text_style_24 text_secondary_clr_dark_gray" />
                          <p>
                            A reentry platform to access your past Messaging App
                            communications you shared during incarceration
                          </p>
                          <p />
                        </div>
                        <a
                          className="prime_button btn_gradient"
                          href="#login"
                          onClick={redirectToLoginProcess}
                        >
                          Log in
                        </a>
                        <div>
                          <p className="text_style_24 text_secondary_clr_dark_gray" />
                          <p style={{ fontSize: 15, fontWeight: 400 }}>
                            Citizen Access allows you to download previously
                            shared texts, photos and video messages from all
                            your contacts. At ViaPath, we recognize the
                            importance of the keepsakes shared with you while
                            you were down. ViaPath will continue to add more
                            resources to your Citizen Access platform, so please
                            check back. Email
                            <span style={{ color: "#049296" }}>
                              {" "}
                              Consumerproduct@viapath.com{" "}
                            </span>{" "}
                            to sign-up for more information.
                          </p>
                          <p />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </main>
        </div>
      </div>
    </Spin>
  );
}
