// import axios from "axios";
import HttpService from "./base.service";

interface IProps {
  data: { codeVerifier: string; code: string; redirectUri: string };
}
interface IJwtToken {
  queryKey: Array<string>;
}

class LoginService extends HttpService {
  accessToken = async ({ data }: IProps) => {
    return this.post(
      `${process.env.REACT_APP_MEMBER_ACCESS_API_BASE_URL}/oauth/token?client_id=${process.env.REACT_APP_MEMBER_ACCESS_CLIENT_ID}&redirect_uri=${data.redirectUri}&grant_type=authorization_code&code=${data.code}&code_verifier=${data.codeVerifier}`,
    );
  };

  getJWTToken = ({ queryKey }: IJwtToken) => {
    return this.getFromMemberAccessApi("api/v1/info", {
      headers: {
        Authorization: `Bearer ${queryKey[1]}`,
      },
    });
  };
}

const loginService = new LoginService();
export default loginService;
