import { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "./styles.css";
import SideDrawer from "components/drawer";
import { useLocation } from "react-router-dom";
import ConfirmModal from "components/header/confirm-modal";
import ReactGA from "react-ga4";
import usePageTracking from "hooks/usePageTracking";
import data from "./header-data.json";

export default function Navbar() {
  const location = useLocation();
  const reactGAInitialized = usePageTracking();
  const isMobileView: boolean = useMediaQuery({
    query: "(max-width: 992px)",
  });

  useEffect(() => {
    if (reactGAInitialized) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    }
  });

  return (
    <header style={{ overflowX: "clip" }}>
      <nav
        className="navbar navbar-expand-lg navbar-dark secondary_clr_dark_gray"
        role="navigation"
        aria-label="Main Menu"
      >
        <div className="container" style={{ width: "100%" }}>
          <div className="navbar-brand">
            <img
              src="https://www.viapath.com/wp-content/uploads/2021/11/logo.svg"
              className="custom_logo"
              alt="logo"
              data-testid="header-image-testid"
            />{" "}
          </div>
          {isMobileView ? (
            <div style={{ textAlign: "right" }}>
              <SideDrawer />
            </div>
          ) : (
            <div className="navbar-collapse">
              <ul id="menu-header-left-menu" className="navbar-nav">
                {data.navlinks.map((el) => (
                  <li
                    key={el.key}
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-96 nav-item"
                  >
                    <a href={el.link} rel="noreferrer" target="_blank">
                      {el.label}
                    </a>
                  </li>
                ))}
              </ul>
              <ul id="menu-header-right-menu" className="navbar-nav right-nav">
                <li className="skew-dropdown menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-482 nav-item">
                  <a href="/" style={{ pointerEvents: "none" }}>
                    About
                  </a>
                  <span className="dropdown-icon" />
                  <ul className="sub-menu">
                    {data.subMenu.map((ele) => (
                      <li
                        key={ele.key}
                        className="menu-item menu-item-type-post_type menu-item-object-page menu-item-98 nav-item"
                      >
                        <a rel="noreferrer" target="_blank" href={ele.link}>
                          {ele.label}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li
                  id="menu-item-97"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-97 nav-item"
                >
                  {location.pathname === "/" ? (
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="https://www.viapath.com/contact-us/"
                    >
                      Contact us
                    </a>
                  ) : (
                    <ConfirmModal />
                  )}
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}
