import { Empty } from "antd";
import type * as CSS from "csstype";

const container: CSS.Properties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
};

interface IProps {
  message: string;
}

export default function NotFoundError({ message }: IProps) {
  return (
    <div style={container} data-testid="no-data-found">
      <Empty style={{ padding: 100 }} description={message} />
    </div>
  );
}
