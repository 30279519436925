import * as CSS from "csstype";

const headerContainer: CSS.Properties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "#313D4D",
  padding: "0 30px",
  height: "90px",
};

export const confirmationHeading: CSS.Properties = {
  display: "block",
  overflow: "hidden",
  color: "#000000d9",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: 1.4,
};

export const logoutIcon: CSS.Properties = {
  color: "#000000d9",
  fontSize: "14px",
};

export const confirmationFooterContainer: CSS.Properties = {
  display: "flex",
  flexDirection: "row-reverse",
};

export default headerContainer;
