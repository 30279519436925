import { useEffect, useState } from "react";
import ReactGA from "react-ga4";

const usePageTracking = () => {
  const [initialized, setInitialized] = useState(false);
  const trackingId: any = process.env.REACT_APP_GA_TRACKING_ID;

  useEffect(() => {
    if (window.location.origin !== window.location.href.slice(0, -1)) {
      ReactGA.initialize(trackingId);
      setInitialized(true);
    }
  }, [trackingId]);
  return initialized;
};

export default usePageTracking;
