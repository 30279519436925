import { PictureOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { IList } from "interfaces/contacts.interface";
import { Avatar, List, Typography, Image } from "antd";
import EContacts from "enums/contacts.enum";
import moment from "moment";
import { Link } from "react-router-dom";
import { getFirstLettersOfString } from "utilities/common";

function ContactsList({ data }: IList) {
  function description() {
    if (data?.type === EContacts.VIDEOMESSAGE) {
      return (
        <>
          <VideoCameraOutlined />
          <Typography.Text
            data-testid="video-text"
            className="custom-light-gray-color"
          >
            {" "}
            Video
          </Typography.Text>
        </>
      );
    }
    if (
      data?.type === EContacts.ECARDMESSAGE ||
      data?.type === EContacts.PHOTOMESSAGE
    ) {
      return (
        <div
          style={{
            display: "flex",
          }}
        >
          <PictureOutlined style={{ paddingTop: "4px", color: "#999" }} />
          <Typography.Text
            data-testid="photo-text"
            style={{
              fontWeight: 500,
              paddingLeft: "5px",
              marginTop: 0,
            }}
            className="custom-light-gray-color"
          >
            {" "}
            Photo
          </Typography.Text>
        </div>
      );
    }

    return (
      <Typography.Paragraph ellipsis style={{ fontSize: 12 }}>
        {data?.body}
      </Typography.Paragraph>
    );
  }

  return (
    <List.Item.Meta
      avatar={
        data?.profile_photo_url ? (
          <Avatar
            size="large"
            src={
              <Image preview={{ mask: null }} src={data?.profile_photo_url} />
            }
          />
        ) : (
          <Avatar size="large" className="named-avatar">
            {getFirstLettersOfString(data?.contact_name)}
          </Avatar>
        )
      }
      title={
        <Link to={`/thread/${data.contact_id}`}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Text> {data?.contact_name}</Typography.Text>
            <Typography.Text className="custom-light-gray-color">
              {moment(data?.sent_at).format("MM-DD-YYYY")}
            </Typography.Text>
          </div>
        </Link>
      }
      description={
        <Link to={`/thread/${data.contact_id}`}>{description()}</Link>
      }
    />
  );
}

export default ContactsList;
