import { Typography } from "antd";
import ContentArea from "components/content/Content";
import container from "./styles";

export default function ExpiredMessage() {
  return (
    <ContentArea>
      <div style={container}>
        <Typography.Text strong>
          Access to your messages has expired
        </Typography.Text>
      </div>
    </ContentArea>
  );
}
