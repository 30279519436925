import { Alert } from "antd";
import useLocalStorage from "hooks/useLocalStorage";
import moment from "moment";
import { parseJwt } from "utilities/common";

export default function ExpireWarningModule() {
  const { getData }: any = useLocalStorage();
  const parsedData = JSON.parse(getData("_token"));
  const decodedJwt = parseJwt(parsedData?.data?.token).inmate;

  return (
    <Alert
      style={{ marginBottom: 8, fontWeight: 500 }}
      description={`Access to your Messaging App communications will expire on: ${moment(
        new Date(decodedJwt.release_date),
      )
        .add(decodedJwt.expiry.split(" ")[0], "days")
        .format("MMMM, DD YYYY")}`}
      type="warning"
      showIcon
    />
  );
}
