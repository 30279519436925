// verifier generator
function dec2hex(dec: number) {
  const str = `0${dec.toString(16)}`;
  return str.substr(-2);
}
function generateRandomString() {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join("");
}
export default function generateVerifier() {
  const verifier = generateRandomString();
  return verifier;
}
// challenge generator
function sha256(plain: string) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}
function base64urlencode(a: any) {
  let str = "";
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}
async function challangeFromVerifier(v: string) {
  const hashed = await sha256(v);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
}
export async function generateChallenge(verifier: string) {
  const challenge = await challangeFromVerifier(verifier);
  return challenge;
}
