import Paragraph from "antd/lib/typography/Paragraph";
import SkeletonLoader from "components/skeleton-loader";
import chatService from "services/chat.service";
import ReceiverChatModule from "components/messages/Receiver";
import SenderChatModule from "components/messages/Sender";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import ChatScreenHeader from "components/chat-screen-header";
import ContentArea from "components/content/Content";
import {
  receiverMessageContainer,
  senderMessageContainer,
} from "components/messages/styles";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import NotFoundError from "components/not-found-module";
import ServerSideError from "components/server-side-error";
import hasMore from "utilities/common";
import { useEffect, useState } from "react";
import { message } from "antd";
import { AxiosError } from "axios";
import useLocalStorage from "../hooks/useLocalStorage";

import {
  IChatMessage,
  IDownloadAPIResponse,
} from "../interfaces/chat.interface";
import WrapperExpiryCheck from "./wrapper/expiry-check";

function Thread() {
  const { removeSession } = useLocalStorage();
  const navigate = useNavigate();
  const params: any = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { user_id: userId } = params;
  let unauthorized = false;
  const defaultDownloadStatus: IDownloadAPIResponse = {
    id: 0,
    download_url: "",
    status: "not_requested",
    inmate_id: 0,
    contact_id: 0,
  };
  const [getDownloadStatus, setGetDownloadStatus] =
    useState<IDownloadAPIResponse | null>(null);

  const { data, fetchNextPage, isLoading, isError, error, refetch }: any =
    useInfiniteQuery(["chat-list", userId], chatService.chat, {
      getNextPageParam: (_lastPage, pages) => {
        return pages.length + 1;
      },
      refetchOnWindowFocus: false,
      retry: (failureCount, err: AxiosError) => {
        return !!(
          failureCount &&
          (err.request.status === 404 || err.request.status === 401)
        );
      },
    });

  const { isLoading: isLoadingDownloadStatus }: any = useQuery(
    "download-status",
    async () => {
      return chatService.downloadStatus(userId);
    },
    {
      refetchOnWindowFocus: false,
      retry: (failureCount, err: AxiosError) => {
        return !!(
          failureCount &&
          (err.request.status === 404 || err.request.status === 401)
        );
      },
      onSuccess: (res) => {
        const result = {
          status: `${res.status}-${res.statusText}`,
          headers: res.headers,
          data: res.data,
        };
        setGetDownloadStatus(result.data[0] || defaultDownloadStatus);
      },
      onError: (err: AxiosError) => {
        if (err.request.status === 404 || err.request.status === 401) {
          setGetDownloadStatus(defaultDownloadStatus);
          return;
        }
        message.error("Something went wrong, please reload the page.");
        setGetDownloadStatus(null);
      },
    },
  );

  const {
    isLoading: isCreatingDownloadRequest,
    mutate: createDownloadRequest,
  } = useMutation(
    async () => {
      return chatService.createDownloadRequest(userId);
    },
    {
      onSuccess: (res) => {
        const result = {
          status: `${res.status}-${res.statusText}`,
          headers: res.headers,
          data: res.data,
        };
        setGetDownloadStatus(result.data);
      },
      onError: () => {
        message.error("Something went wrong, please try again.");
        setGetDownloadStatus(defaultDownloadStatus);
      },
    },
  );

  useEffect(() => {
    if (isLoadingDownloadStatus) setGetDownloadStatus(null);
    if (isCreatingDownloadRequest) setGetDownloadStatus(null);
    if (unauthorized) {
      navigate("/");
    }
  }, [
    isLoadingDownloadStatus,
    isCreatingDownloadRequest,
    navigate,
    unauthorized,
  ]);

  const renderChatList = () => {
    if (isError) {
      if (error?.request?.status === 401) {
        removeSession();
        unauthorized = true;
      }
      return <ServerSideError error={error} refetch={refetch} />;
    }
    const totalLength = data.pages
      .map((el: any) => el)
      .map((ele: any) => ele.data.list)
      .flat().length;

    if (totalLength > 0) {
      return (
        <InfiniteScroll
          dataLength={totalLength}
          next={fetchNextPage}
          className="infinite-scroll-bar"
          hasMore={hasMore(data.pages)}
          loader={<SkeletonLoader />}
          endMessage=""
          scrollableTarget="scrollableDiv"
        >
          {data?.pages.map((group: any) => {
            const { list } = group.data;
            return list?.map((el: IChatMessage) => {
              return String(el.sender_id) === userId ? (
                <SenderChatModule data={el} key={el.message_id} />
              ) : (
                <ReceiverChatModule data={el} key={el.message_id} />
              );
            });
          })}
        </InfiniteScroll>
      );
    }
    return <NotFoundError message="There are no chats in your feed" />;
  };

  return (
    <>
      <ChatScreenHeader
        userId={userId}
        isError={isError}
        isLoading={isLoading}
        data={data}
        isEmpty={data?.pages[0].data.list.length === 0}
        download={getDownloadStatus}
        onDownloadClick={createDownloadRequest}
      />
      <ContentArea>
        <Paragraph>
          {isLoading ? (
            <div data-testid="thread-loader">
              {[0, 1, 2].map((el: number) => (
                <div key={el.toString()}>
                  <div style={receiverMessageContainer}>
                    <SkeletonLoader />
                  </div>
                  <div style={senderMessageContainer}>
                    <SkeletonLoader />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            renderChatList()
          )}
        </Paragraph>
      </ContentArea>
    </>
  );
}

export default WrapperExpiryCheck(Thread);
