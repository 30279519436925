import type * as CSS from "csstype";

export const contentContainer: CSS.Properties = {
  width: "90%",
  margin: "auto",
  minHeight: "700px",
};

export const contentContainerMobileView: CSS.Properties = {
  // width: "85%",
  // margin: "auto",
  padding: "0 30px",
  minHeight: "520px",
};
