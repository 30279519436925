import { useState } from "react";
import { Button, Drawer, Menu, MenuProps } from "antd";
import { CloseOutlined, LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import ConfirmModal from "components/header/confirm-modal";
import useLocalStorage from "hooks/useLocalStorage";
import { parseJwt } from "utilities/common";
import moment from "moment";
import drawerMenuData from "./drawer-menu-data.json";

export default function SideDrawer() {
  const [open, setOpen] = useState(false);
  type MenuItem = Required<MenuProps>["items"][number];
  const { getData }: any = useLocalStorage();
  const parsedData = JSON.parse(getData("_token"));
  const decodedJwt =
    parsedData?.data?.token && parseJwt(parsedData?.data?.token).exp;

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    link?: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group",
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
      link,
      onClick: () => {
        if (link !== undefined) {
          window.open(link, "_blank");
        }
      },
    } as MenuItem;
  }

  const showDrawer = () => {
    setOpen(!open);
  };

  const onClose = () => {
    setOpen(false);
  };

  const items: MenuItem[] = drawerMenuData.map((el) =>
    getItem(
      el.label,
      el.key,
      el.link,
      undefined,
      el.children?.map((ele) => getItem(ele.label, ele.key, ele.link)),
    ),
  );

  return (
    <>
      <Button
        style={{
          background: "transparent",
          border: "none",
          color: "white",
        }}
        shape="circle"
        onClick={showDrawer}
        icon={<MenuOutlined style={{ fontSize: 25 }} />}
        size="large"
      />

      <Drawer
        title={
          <Button
            style={{
              background: "transparent",
              border: "none",
              color: "white",
            }}
            shape="circle"
            onClick={showDrawer}
            icon={<CloseOutlined />}
            size="large"
          />
        }
        placement="left"
        closable={false}
        onClose={onClose}
        open={open}
        key="left"
      >
        <Menu
          style={{ backgroundColor: "#049296", color: "white" }}
          mode="inline"
          items={[
            ...items,
            parsedData?.data?.token &&
              moment(decodedJwt * 1000).format() > moment().format() &&
              getItem(
                <ConfirmModal />,
                "logout-btn",
                undefined,
                <LogoutOutlined />,
              ),
          ]}
        />
      </Drawer>
    </>
  );
}
