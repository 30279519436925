import ReactGA from "react-ga4";

export default function useGAEventTracker(category = "Event Category") {
  const trackEvent = (action = "action") => {
    ReactGA.event({
      category,
      action,
    });
  };
  return trackEvent;
}

export function useGACustomDimensionTracker(category = "Event Category") {
  const trackEvent = (action = "action") => {
    ReactGA.event({
      category,
      action,
    });
  };
  return trackEvent;
}
