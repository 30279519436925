import { useState } from "react";
import { Button, message, Modal, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import useLocalStorage from "hooks/useLocalStorage";
import { LogoutOutlined } from "@ant-design/icons";
import {
  confirmationFooterContainer,
  confirmationHeading,
  logoutIcon,
} from "./styles";

export default function ConfirmModal() {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { removeSession } = useLocalStorage();
  const redirectUri = window.location.origin;

  const logout = () => {
    removeSession();
    navigate("/");
    message.info("You have been logged out!");
    window.open(
      `${process.env.REACT_APP_MEMBER_ACCESS_OAUTH_URL}/api/v1/logout?redirect_uri=${redirectUri}`,
      "_self",
    );
  };

  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };

  return (
    <>
      <a onClick={showModal} href="#logout-modal" data-testid="logout-button">
        Logout
      </a>
      <Modal
        footer={false}
        maskClosable={false}
        closable={false}
        open={open}
        width={450}
      >
        <div style={{ display: "flex", padding: 20 }}>
          <div>
            <LogoutOutlined style={{ color: "#FAAD14", fontSize: 20 }} />
          </div>
          <div style={{ paddingLeft: 15 }}>
            <Typography.Text style={confirmationHeading}>
              Confirmation
            </Typography.Text>
            <Typography.Paragraph style={logoutIcon}>
              Are you sure you want to logout?
            </Typography.Paragraph>
          </div>
        </div>
        <div style={confirmationFooterContainer}>
          <Button type="primary" data-testid="logout-function" onClick={logout}>
            Yes
          </Button>
          <Button
            onClick={hideModal}
            data-testid="cancel-logout"
            style={{ marginRight: 7 }}
          >
            No
          </Button>
        </div>
      </Modal>
    </>
  );
}
