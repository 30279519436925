import useLocalStorage from "hooks/useLocalStorage";
import moment from "moment";
import { Outlet, Navigate } from "react-router-dom";
import { parseJwt } from "utilities/common";

export default function PublicRoute() {
  const { getData }: any = useLocalStorage();
  const parsedData = JSON.parse(getData("_token"));
  const decodedJwt =
    parsedData?.data?.token && parseJwt(parsedData?.data?.token).exp;

  return parsedData?.data?.token &&
    moment(decodedJwt * 1000).format() > moment().format() ? (
    <Navigate to="/contacts" />
  ) : (
    <Outlet />
  );
}
