import THREAD_PAGE_SIZE from "constants/thread.constants";
import HttpService from "./base.service";

class ChatService extends HttpService {
  private readonly prefix: string = "messages";

  /**
   * Contact List
   */
  chat = ({ pageParam = 0, queryKey }: any) =>
    this.get(
      `${process.env.REACT_APP_API_ENDPOINT}/${this.prefix}/chat?user_id=${
        queryKey[1]
      }&limit=${THREAD_PAGE_SIZE}&page_number=${
        pageParam === 0 ? 0 : pageParam - 1
      }`,
    );

  downloadStatus = (contactId: string): any => {
    return this.get(
      `${process.env.REACT_APP_DOWNLOAD_API_ENDPOINT}/download_request?contact_id=${contactId}`,
    );
  };

  createDownloadRequest = (contactId: string): any => {
    return this.post(
      `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_DOWNLOAD_API_ENDPOINT}/download_request?contact_id=${contactId}`,
      { download_request: { contact_id: contactId } },
    );
  };
}

const chatService = new ChatService();
export default chatService;
