import { Route, Routes } from "react-router-dom";
import Contacts from "./Contact";
import Thread from "./Thread";

export default function Dashboard() {
  return (
    <Routes>
      <Route path="contacts" element={<Contacts />} />
      <Route path="thread/:user_id" element={<Thread />} />
    </Routes>
  );
}
