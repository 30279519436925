import ExpiredMessage from "components/expired-message";
import useLocalStorage from "hooks/useLocalStorage";
import { parseJwt } from "utilities/common";
import moment from "moment";

export default function WrapperExpiryCheck(Component: any) {
  function Authenticated() {
    const { getData }: any = useLocalStorage();
    const parsedData = JSON.parse(getData("_token"));
    const decodedJwt = parseJwt(parsedData?.data?.token).inmate;

    if (
      new Date() >
      moment(new Date(decodedJwt.release_date))
        .add(decodedJwt.expiry.split(" ")[0], "days")
        .toDate()
    ) {
      return <ExpiredMessage />;
    }
    return <Component />;
  }
  return Authenticated;
}
