import { Col, Row } from "antd";
import ExpireWarningModule from "components/expire-warning";

function ContactsHeader() {
  return (
    <Row
      align="middle"
      style={{
        borderBottom: "3px solid #EAEAEA",
        paddingTop: 20,
        paddingBottom: 5,
      }}
    >
      <Col span={24}>
        <h2>Messages</h2>
      </Col>
      <Col span={24}>
        <ExpireWarningModule />
      </Col>
    </Row>
  );
}

export default ContactsHeader;
