import CONTACTS_PAGE_SIZE from "constants/contacts.constants";
import HttpService from "./base.service";

class ContactService extends HttpService {
  private readonly prefix: string = "messages";

  /**
   * Contact List
   */
  list = ({ pageParam = 0 }) =>
    this.get(
      `${process.env.REACT_APP_API_ENDPOINT}/${
        this.prefix
      }?limit=${CONTACTS_PAGE_SIZE}&page_number=${
        pageParam === 0 ? 0 : pageParam - 1
      }`,
    );
}

const contactService = new ContactService();
export default contactService;
