import { Layout } from "antd";
import { useMediaQuery } from "react-responsive";
import { contentContainer, contentContainerMobileView } from "./styles";

const { Content } = Layout;
interface IProps {
  children: any;
}

function ContentArea({ children }: IProps) {
  const isMobileView: boolean = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <Content
      style={isMobileView ? contentContainerMobileView : contentContainer}
      data-testid={isMobileView ? `mobile-view-testid` : `desktop-view-testid`}
    >
      <div className="site-layout-content">{children}</div>
    </Content>
  );
}

export default ContentArea;
