/* eslint-disable */

import axios, { CancelTokenSource, CancelTokenStatic } from "axios";

export default class HttpService {
  CancelToken: CancelTokenStatic;

  source: CancelTokenSource;

  constructor() {
    this.CancelToken = axios.CancelToken;
    this.source = this.CancelToken.source();
  }

  static setToken(token: string): void {
    (axios as any).defaults.headers["Authorization"] = `Bearer ${token}`;
  }

  /**
   * Fetch data from server
   * @param url Endpoint link
   * @return Promise
   */
  protected get = async (url: string, params?: any): Promise<any> => {
    const test = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/${url}`,
      {
        ...params,
        cancelToken: this.source.token,
      },
    );

    return test;
  };

    /**
   * Fetch data from legal
   * @param url Endpoint link
   * @return Promise
   */
     protected getFromLegal = async (url: string): Promise<any> => {
      return await axios.get(
        `${process.env.REACT_APP_LEGAL_URL}/${url}`,
      );
    };

  /**
   * Fetch data from server member access api
   * @param url Endpoint link
   * @return Promise
   */
  protected getFromMemberAccessApi = async (
    url: string,
    params?: any,
  ): Promise<any> => {
    const test = await axios.get(
      `${process.env.REACT_APP_MEMBER_ACCESS_API_BASE_URL}/${url}`,
      {
        ...params,
        cancelToken: this.source.token,
      },
    );

    return test;
  };

  /**
   * Write data over server
   * @param url Endpoint link
   * @param body Data to send over server
   * @return Promise
   */
  protected post = async (
    url: string,
    body?: any,
    options?: any,
  ): Promise<any> => {
    const result = await axios.post(`${url}`, body, {
      ...options,
      cancelToken: this.source.token,
    });

    return result;
  };

  private updateCancelToken() {
    this.source = this.CancelToken.source();
  }

  cancel = () => {
    this.source.cancel("Explicitly cancelled HTTP request");
    this.updateCancelToken();
  };
}
