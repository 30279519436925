import { Button, Modal } from "antd";
import { useState } from "react";
// import { Player } from "video-react";
import { PlayCircleOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player/lazy";

interface IProps {
  data: {
    thumbnail: string;
    media: string;
  };
}

export default function VideoModal({ data }: IProps) {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleVisible = () => {
    setIsModalVisible(true);
  };

  return (
    <>
      <div
        style={{
          position: "relative",
          width: 200,
          height: 200,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          onClick={handleVisible}
          data-testid="open-modal-button"
          type="primary"
          shape="circle"
          style={{
            position: "absolute",
            zIndex: 100,
            height: 100,
            width: 100,
            color: "white",
            backgroundColor: "transparent",
          }}
          icon={<PlayCircleOutlined style={{ fontSize: 50 }} />}
        />

        <img
          width={200}
          height={200}
          src={data?.thumbnail}
          alt={data?.thumbnail}
        />
      </div>
      <Modal
        title="Video"
        open={isModalVisible}
        onCancel={handleCancel}
        footer=""
      >
        <ReactPlayer
          data-testid="video-player"
          width="100%"
          url={data?.media}
          controls
          pip
        />
      </Modal>
    </>
  );
}
