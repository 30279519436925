import { IContactAPIResponse } from "@interfaces/contacts.interface";
import CONTACTS_PAGE_SIZE from "constants/contacts.constants";

export function parseJwt(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

export function getFirstLettersOfString(str: string) {
  const firstLetters = str
    .split(" ")
    .map((word) => word[0])
    .join("");

  return firstLetters;
}

const hasMore = (allpages: IContactAPIResponse[]) => {
  return allpages[allpages.length - 1].data.list.length === CONTACTS_PAGE_SIZE;
};

export default hasMore;
