import HttpService from "./base.service";

class SharedService extends HttpService {
  private readonly legalUrl = "terms/en-us/index.html";

  getTermsAndConditions = (): Promise<any> => {
    return this.getFromLegal(this.legalUrl);
  };
}

const sharedService = new SharedService();
export default sharedService;
