import type * as CSS from "csstype";

// sender's styling
export const senderTextStyle: CSS.Properties = {
  float: "right",
  backgroundColor: "#D8E7F2",
  borderRadius: "10px",
  borderColor: "#ddd",
  // maxWidth: "85%",
};

export const senderImageStyle: CSS.Properties = {
  float: "right",
  // minWidth: "50%",
  // marginLeft: "50%",
  backgroundColor: "#D8E7F2",
  borderRadius: "10px",
  borderColor: "#ddd",
};

export const senderMessageContainer: CSS.Properties = {
  width: "80%",
  display: "block",
  margin: "0",
  clear: "both",
  minHeight: "70px",
  marginLeft: "auto",
};

// receiver's styling
export const receiverTextStyle: CSS.Properties = {
  float: "left",
  // minWidth: "50%",
  marginRight: "0%",
  backgroundColor: "#fff",
  borderRadius: "10px",
  borderColor: "#eee",
  maxWidth: "85%",
};

export const receiverImageStyle: CSS.Properties = {
  float: "left",
  // minWidth: "50%",
  // marginRight: "50%",
  backgroundColor: "#fff",
  borderRadius: "10px",
  borderColor: "#eee",
};

export const receiverMessageContainer: CSS.Properties = {
  width: "80%",
  display: "block",
  margin: "0",
  clear: "both",
  minHeight: "70px",
};
