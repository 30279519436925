import "./App.css";
import { useLayoutEffect } from "react";
// react query
import { QueryClient, QueryClientProvider } from "react-query";

import useLocalStorage from "hooks/useLocalStorage";
import { Layout } from "antd";
import Dashboard from "routes/Dashboard";
import PrivateRoute from "routes/PrivateRoute";
import HttpService from "services/base.service";
import PublicRoute from "routes/PublicRoute";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "routes/Home";
import Navbar from "components/header";
import Footer from "components/footer";

const queryClient = new QueryClient();

function App() {
  const { getData } = useLocalStorage();

  useLayoutEffect(() => {
    const token = getData("_token");
    if (token) {
      HttpService.setToken(JSON.parse(token)?.data?.token);
    }
  }, [getData]);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Navbar />
        <Layout>
          <Routes>
            {/* not accessible by the authenticated user */}
            <Route element={<PublicRoute />}>
              <Route path="/" element={<Home />} />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/*"
                element={
                  <div
                    style={{
                      width: "100%",
                      margin: "auto",
                    }}
                  >
                    <Dashboard />
                  </div>
                }
              />
            </Route>
          </Routes>
        </Layout>
        <Footer />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
