import loginService from "services/login.service";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import HttpService from "services/base.service";
import ReactGA from "react-ga4";
import { useLocation, useNavigate } from "react-router-dom";
import generateVerifier, { generateChallenge } from "utilities/generator";
import { parseJwt } from "utilities/common";
import useLocalStorage from "./useLocalStorage";

export default function useLogin() {
  const location = useLocation();
  const navigate = useNavigate();
  const { setValue, getData }: any = useLocalStorage();
  const [isSpinning, setIsSpinning] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string>("");
  const redirectUri = window.location.origin;

  const { mutate } = useMutation(loginService.accessToken, {
    onSuccess: async (data) => {
      setAccessToken(data.data.access_token);
    },
    onError: () => {
      setIsSpinning(false);
    },
  });

  React.useEffect(() => {
    if (location.search.split("?code=")[1]) {
      setIsSpinning(true);
      const getCodeVerifier = getData("verifier");
      if (getCodeVerifier) {
        mutate({
          data: {
            code: location.search.split("?code=")[1],
            codeVerifier: getCodeVerifier,
            redirectUri,
          },
        });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const processLogin = (data: any) => {
    const facilityId = parseJwt(data.data.token).inmate.facility_id;
    ReactGA.set({ userId: data.data.id });
    ReactGA.gtag("set", "user_properties", {
      facilityId,
    });

    setAccessToken("");
    HttpService.setToken(data.data.token);
    setValue("_token", JSON.stringify(data));
    navigate("/contacts");
  };

  useQuery(["jwt-token", accessToken], loginService.getJWTToken, {
    enabled: accessToken !== "",
    onSuccess: (data) => {
      processLogin(data);
    },
  });

  const redirectToLoginProcess = async () => {
    const verifier = await generateVerifier();
    setValue("verifier", verifier);
    const challenge = await generateChallenge(verifier);
    window.open(
      `${process.env.REACT_APP_MEMBER_ACCESS_OAUTH_URL}/oauth/authorize?client_id=${process.env.REACT_APP_MEMBER_ACCESS_CLIENT_ID}&scope=&response_type=code&redirect_uri=${redirectUri}&code_challenge=${challenge}&code_challenge_method=S256`,
      "_self",
    );
  };
  return { redirectToLoginProcess, isSpinning, setAccessToken, processLogin };
}
