import { DownloadOutlined } from "@ant-design/icons";
import {
  IChatMessage,
  IChatReactQueryResponse,
  IDownloadAPIResponse,
} from "@interfaces/chat.interface";
import {
  Button,
  Col,
  PageHeader,
  Row,
  Typography,
  Avatar,
  Image,
  Spin,
} from "antd";
import type * as CSS from "csstype";
import useGAEventTracker from "hooks/useGAEventTracker";
import EDownloadStatus from "enums/chat.enum";
import { useNavigate } from "react-router-dom";
import { getFirstLettersOfString } from "utilities/common";

const styles: CSS.Properties = {
  borderColor: "#00B2D0",
  borderWidth: "2px",
  backgroundColor: "transparent",
  color: "#00B2D0",
  borderRadius: "8px",
};

interface IProps {
  userId: string;
  isError: boolean;
  isLoading: boolean;
  data: IChatReactQueryResponse;
  isEmpty: boolean;
  download: IDownloadAPIResponse | null;
  onDownloadClick: Function;
}
function getContactDetails(
  array: IChatReactQueryResponse,
  key: string,
  contactId: string,
) {
  return array.pages
    .map((el) => el)
    .map((ele: any) => ele.data.list)
    .flat()
    .find((el: IChatMessage) => {
      return String(el.contact_id) === String(contactId);
    })[key];
}

function calculateDownloadText(download: IDownloadAPIResponse | null) {
  if (download?.status === "requested") {
    return (
      <>
        <Spin size="small" style={{ marginRight: "15px" }} />
        {EDownloadStatus[download?.status]}
      </>
    );
  }
  if (download?.status === "ready" || download?.status === "not_requested") {
    return (
      <>
        <DownloadOutlined style={{ marginRight: "15px" }} />
        {EDownloadStatus[download.status]}
      </>
    );
  }
  return (
    <>
      <Spin size="small" style={{ marginRight: "15px" }} /> Loading...
    </>
  );
}

function downloadClickHandler(
  download: IDownloadAPIResponse | null,
  contactId: string,
  onDownloadClick: Function,
  gaEventForQueued: Function,
  gaEventForDownload: Function,
) {
  if (download?.status === "not_requested") {
    onDownloadClick(contactId);
    gaEventForQueued("Downloading is queued");
  } else if (download?.status === "ready" && download.download_url !== "") {
    gaEventForDownload("Thread Downloaded");
    window.open(download.download_url, "_blank");
  }
}

function ChatScreenHeader({
  userId,
  isError,
  isLoading,
  data,
  isEmpty,
  download,
  onDownloadClick,
}: IProps) {
  const navigate = useNavigate();
  const gaEventForQueued = useGAEventTracker("Download Queued Event");
  const gaEventForDownload = useGAEventTracker("Downloaded Event");

  return (
    <div className="site-page-header-ghost-wrapper">
      {isError || isLoading || isEmpty ? (
        <PageHeader
          data-testid="empty-rendered"
          onBack={() => navigate("/contacts")}
          title=" "
        />
      ) : (
        <PageHeader
          ghost={false}
          onBack={() => navigate("/contacts")}
          title={
            getContactDetails(data, "profile_photo_url", userId) ? (
              <Avatar
                size="large"
                src={
                  <Image
                    preview={{ mask: null }}
                    src={getContactDetails(data, "profile_photo_url", userId)}
                  />
                }
              />
            ) : (
              <Avatar size="large" className="named-avatar">
                {getFirstLettersOfString(
                  getContactDetails(data, "contact_name", userId),
                )}
              </Avatar>
            )
          }
        >
          <Row>
            <Col lg={{ span: 19 }} xs={{ span: 24 }} sm={{ span: 24 }}>
              <Typography.Title level={3} style={{ fontWeight: 400 }}>
                {getContactDetails(data, "contact_name", userId)}
              </Typography.Title>
            </Col>
            <Col lg={{ span: 5 }} xs={{ span: 24 }} sm={{ span: 24 }}>
              <Button
                type="primary"
                data-testid="download-click-button"
                size="large"
                block
                className="button-border-color"
                disabled={!download || download.status === "requested"}
                style={styles}
                onClick={() =>
                  downloadClickHandler(
                    download,
                    userId,
                    onDownloadClick,
                    gaEventForQueued,
                    gaEventForDownload,
                  )
                }
              >
                {calculateDownloadText(download)}
              </Button>
            </Col>
          </Row>
        </PageHeader>
      )}
    </div>
  );
}

export default ChatScreenHeader;
