import { ISenderAndReceiver } from "interfaces/chat.interface";
import { Image } from "antd";
import VideoModal from "components/video-modal";
import EContacts from "enums/contacts.enum";
import moment from "moment";
import {
  senderImageStyle,
  senderTextStyle,
  senderMessageContainer,
} from "./styles";

export default function SenderChatModule({ data }: ISenderAndReceiver) {
  if (
    data?.type === EContacts.PHOTOMESSAGE ||
    data?.type === EContacts.ECARDMESSAGE
  ) {
    return (
      <div style={senderMessageContainer} data-testid="mock_sender_id">
        {" "}
        <pre style={senderImageStyle}>
          <h6>
            You{" "}
            <code style={{ float: "right" }}>
              {moment(data?.sent_at).format("MM-DD-YYYY")}
            </code>
          </h6>
          <Image width={200} height={200} src={data?.thumbnail_url} />
        </pre>
      </div>
    );
  }
  if (data?.type === EContacts.VIDEOMESSAGE) {
    return (
      <div style={senderMessageContainer} data-testid="mock_sender_id">
        {" "}
        <pre style={senderImageStyle}>
          <h6>
            You{" "}
            <code style={{ float: "right" }}>
              {moment(data?.sent_at).format("MM-DD-YYYY")}
            </code>
          </h6>
          <VideoModal
            data={{ thumbnail: data?.thumbnail_url, media: data?.media_url }}
          />
        </pre>
      </div>
    );
  }
  return (
    <div style={senderMessageContainer} data-testid="mock_sender_id">
      {" "}
      <pre style={senderTextStyle}>
        <h6>
          You{" "}
          <code style={{ float: "right", paddingLeft: 10 }}>
            {moment(data?.sent_at).format("MM-DD-YYYY")}
          </code>
        </h6>
        {data?.body}
      </pre>
    </div>
  );
}
