import { message, Modal, Spin } from "antd";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import sharedService from "services/shared.service";
import parse from "html-react-parser";
import footerLinks from "../header/header-data.json";
import secondRowLinks from "./links.json";
import socialLinks from "./social-links.json";

import "../header/styles.css";

function htmlToReactParsing(content: any) {
  const el = document.createElement("div");
  el.innerHTML = content.data;
  return parse(el.innerHTML);
}

export default function Footer() {
  const {
    data: termsContent,
    isLoading: isLoadingTerms,
    error,
  }: any = useQuery(
    "terms-n-conditions",
    async () => {
      return sharedService.getTermsAndConditions();
    },
    {
      refetchOnWindowFocus: false,
      retry: (failureCount, err: AxiosError) => {
        return !!(failureCount && err.request.status === 404);
      },
    },
  );
  if (error) {
    message.error("Something went wrong, please reload the page.");
  }
  const info = () => {
    Modal.info({
      icon: false,
      centered: true,
      closable: true,
      autoFocusButton: null,
      okText: "Close",
      width: 1028,
      content: isLoadingTerms ? (
        <div data-testid="modal-content">
          <Spin />
        </div>
      ) : (
        <div data-testid="modal-content">
          {htmlToReactParsing(termsContent)}
        </div>
      ),
    });
  };

  return (
    <footer className="footer-block">
      <div className="bg-content">
        <img
          alt="foot-bg"
          className="img-fluid bg-image"
          src="https://www.viapath.com/wp-content/uploads/2021/11/foot-bg-1.png"
        />
      </div>
      <div className="container cstm-index">
        <div className="row my-custom-row">
          <div className="col-md-3">
            <div className="footer-logo">
              <img
                alt="Logo"
                className="img-fluid"
                src="https://www.viapath.com/wp-content/uploads/2021/11/logo-1.svg"
              />
            </div>
            <div className="logo-content">
              <div className="footer-icons">
                <ul>
                  {socialLinks.map((el) => (
                    <li key={el.alt}>
                      <a href={el.link} rel="noreferrer" target="_blank">
                        <img alt={el.alt} className="img-fluid" src={el.img} />
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-list">
              <div className="menu-footer-first-menu-container">
                <ul id="menu-footer-first-menu" className="menu">
                  {footerLinks.navlinks.map((el) => (
                    <li
                      key={el.key}
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-102 nav-item"
                    >
                      <a href={el.link} rel="noreferrer" target="_blank">
                        {el.label}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>{" "}
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-list">
              <div className="menu-footer-second-menu-container">
                <ul id="menu-footer-second-menu" className="menu">
                  {secondRowLinks.map((el) => (
                    <li
                      key={el.key}
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-103 nav-item"
                    >
                      <a href={el.link} rel="noreferrer" target="_blank">
                        {el.label}
                      </a>
                    </li>
                  ))}

                  <li
                    id="menu-item-1920"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-1920 nav-item"
                  >
                    <a
                      data-testid="terms-conditions-link"
                      href="#terms"
                      onClick={info}
                      rel="noreferrer"
                    >
                      Terms and Conditions
                    </a>
                  </li>
                </ul>
              </div>{" "}
            </div>
          </div>
          <div className="col-md-3">
            <div className="footer-container">
              <div className="text_style_18">
                <p>
                  We would love to get to know you
                  <strong>Get in touch</strong>
                </p>
              </div>
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.viapath.com/contact-us/"
                className="prime_button btn_gradient btn_white "
              >
                Contact us
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
